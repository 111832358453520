// External packages
import * as React from "react"
import { Flex, Heading, Paragraph } from "theme-ui"
import { z } from "zod"

// Services
import Medusa from "../../../services/api"
import { subscribedToNewsletter } from "../../../services/analytics"

// Context
import { useNotificationContext } from "../../../context/NotificationContext"

// Components
import { TextInlineButtonField } from "../../../components/v2/form/TextInlineButtonField"
import { Checkbox } from "../../../components/v2/ui/Checkbox"
import { Link } from "../../../components/v2/Link"

export const NewsletterInput = ({
  showDescription,
  onComplete = () => {},
  jaq = false,
  subtitle,
  archiveSale = false,
  title,
  exclusives = false,
  source,
}) => {
  return (
    <>
      {showDescription && (
        <NewsletterDescription jaq={jaq} title={title} subtitle={subtitle} />
      )}
      <NewsletterSignupField
        onComplete={onComplete}
        jaq={jaq}
        archiveSale={archiveSale}
        exclusives={exclusives}
        source={source}
      />
    </>
  )
}

export const NewsletterDescription = ({
  title,
  subtitle,
  additionalContent,
  jaq = false,
}) => {
  return (
    <>
      <Paragraph sx={{ fontSize: "md", marginBlockEnd: 3 }}>
        {title ? title : "Keep in touch"}
      </Paragraph>
      <Paragraph sx={{ fontSize: "sm", marginBlockEnd: 6 }}>
        {jaq
          ? "Be the first to know when Tekla / Jacquemus launches"
          : subtitle
          ? subtitle
          : "Sign up to our newsletter and receive a 10% discount on your next order."}
      </Paragraph>
      {additionalContent && (
        <Paragraph sx={{ marginBlockEnd: 4 }}>{additionalContent}</Paragraph>
      )}
    </>
  )
}

export const NewsletterSignupField = ({
  onComplete,
  jaq = false,
  archiveSale = false,
  exclusives = false,
  source,
}) => {
  const [email, setEmail] = React.useState()
  const [consent, setConsent] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState("")
  const [consentHasError, setConsentHasError] = React.useState(false)

  const { pushNotification } = useNotificationContext()

  const handleSignup = () => {
    try {
      z.string().email().parse(email)
    } catch (error) {
      setErrorMessage("Please enter a valid email address")
      return
    }

    setErrorMessage("")

    if (!consent) {
      setConsentHasError(true)
      return
    }

    setConsentHasError(false)

    let payload = {
      email: email,
      ids: {},
      jaq: jaq || archiveSale,
      exclusives: exclusives,
      source,
    }

    Medusa.newsletter
      .signup(payload)
      .then(({ data }) => {
        if (onComplete) {
          onComplete()
          setEmail("")
          setConsent(false)
        }

        if (data.user_exists) {
          pushNotification({
            id: "sign_up_complete",
            body: "This email is already subscribed",
            dismiss: {
              duration: 3000,
            },
          })
        } else {
          subscribedToNewsletter(payload)

          pushNotification({
            id: "sign_up_complete",
            body: "Thank you. You will receive an email with your welcome offer shortly.",
            dismiss: {
              duration: 3000,
            },
          })
        }
      })
      .catch(() => {
        pushNotification({
          id: "sign_up_failed",
          body: "Sorry, something went wrong. Make sure the input is correct and try again",
          dismiss: {
            duration: 3000,
          },
        })
      })
  }

  return (
    <Flex sx={{ flexDirection: "column" }}>
      <TextInlineButtonField
        inputProps={{
          placeholder: "Enter your email",
          type: "text",
          hasFloatingLabel: true,
          subLabel: errorMessage,
          hasError: Boolean(errorMessage),
          onChange: (e) => setEmail(e.target.value),
          value: email,
        }}
        buttonProps={{
          children: "Subscribe",
          onClick: handleSignup,
        }}
      />
      {email?.length > 0 && (
        <>
          <Checkbox
            value={consent}
            onClick={() => setConsent((prev) => !prev)}
            label={
              <>
                Subcribe to receive communications from Tekla. By subscribing,
                you confirm you have read and understood our{" "}
                <Link to="/privacy-policy" variant="underline">
                  Privacy Policy
                </Link>
                .
              </>
            }
            hasError={consentHasError && !consent}
            sx={{ marginBlockStart: 4 }}
          />
        </>
      )}
    </Flex>
  )
}
