import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query"
import axios, { AxiosResponse } from "axios"
import medusaRequest from "../../services/request"
import { PricedProduct } from "@medusajs/medusa/dist/types/pricing"
import { Cart, Product } from "@medusajs/medusa"
import { kebabCase } from "lodash"
import { getTypePlural } from "../../utils/getTypePlural"
import { getSizeAcronym } from "../../utils/functions/getSizeAcronym"
import { getMinimumPrice } from "../../components/v2/utils/product/getMinimumPrice"
import { getMaximumPrice } from "../../components/v2/utils/product/getMaximumPrice"

const LUIGIS_BOX_HOST = "https://live.luigisbox.com"
const TRACKER_ID = process.env.LUIGIS_BOX_TRACKER_ID ?? "478593-581244"

export interface AutocompleteResponse {
  exact_match_hits_count: number
  partial_match_hits_count: number
  partial_match_terms: Array<any>
  hits: Array<{
    url: string
    attributes: {
      image_link: string
      description: string
      categories: Array<string>
      title: string
      "title.untouched": string
      availability_rank_text: string
      price: string
      condition: string
    }
    type: string
  }>
  campaigns: Array<{
    id: number
    target_url: string
    banners: {
      autocomplete_list?: {
        desktop_url: string
        mobile_url: string
      }
      autocomplete_top?: {
        desktop_url: string
        mobile_url: string
      }
    }
  }>
  suggested_url: string
}

export interface SuggestionsResponse {
  data: Array<{
    generated_at: string
    model_version: number
    recommendation_id: string
    recommendation_type: string
    recommender_client_identifier: string
    recommender: string
    recommender_version: string
    user_id: string
    hits: Array<{
      url: string
      attributes: {
        image_link: string
        description: string
        categories: Array<string>
        title: string
        availability_rank_text: string
        price: string
        condition: string
      }
      type: string
      exact?: boolean
    }>
  }>
}

export interface SearchResponse {
  results: {
    total_hits: number
    hits: Array<{
      url: string
      attributes: {
        image_link: string
        description: string
        categories: Array<string>
        categories_count: number
        title: string
        "title.untouched": string
        availability_rank_text: string
        price: string
        condition: string
        original_url: string
        url: string
      }
      type: string
    }>
    facets: Array<{
      name: string
      type: string
      values: Array<{
        value: string
        hits_count: number
      }>
    }>
    offset: string
    campaigns: Array<{
      id: number
      target_url: string
      banners: {
        search_header: {
          desktop_url: string
          mobile_url: string
        }
        search_footer: {
          desktop_url: string
          mobile_url: string
        }
      }
    }>
  }
  next_page: string
}

export interface SearchNoResultsSuggestedProductsResponse {
  suggested_products: Product[]
}

export const useAutocomplete = (
  search: string,
  cart: Readonly<Cart>,
  limit = 12
): UseQueryResult<PricedProduct[] | [], unknown> => {
  return useQuery(
    ["autocomplete", search, cart?.id, limit],
    async () => {
      if (!search || !cart?.id) {
        return []
      }

      const data: AxiosResponse<AutocompleteResponse> = await axios(
        `${LUIGIS_BOX_HOST}/autocomplete/v2`,
        {
          method: "GET",
          params: {
            q: search,
            tracker_id: TRACKER_ID,
            type: `product:${limit}`,
          },
        }
      )

      if (!data.data?.hits?.length) {
        return []
      }

      const { data: MedusaRes } = await medusaRequest(
        "GET",
        `store/products?id[]=${data.data.hits.map(
          (hit) => hit.url
        )}&expand=categories,variants,color,variants.prices,type&region_id=${
          cart.region_id
        }&cart_id=${cart.id}&currency_code=${cart.region.currency_code}`
      )

      if (!MedusaRes.products.length) {
        return []
      }

      return data.data.hits
        .map((hit) => {
          const product = MedusaRes.products.find((p) => p.id === hit.url)

          if (!product) {
            return null
          }

          return product
        })
        .filter(Boolean)
    },
    {
      keepPreviousData: true,
    }
  )
}

export const useSuggestions = (userId: string, recommendationType: string) => {
  return useQuery(["suggestions", userId, recommendationType], async () => {
    const data: AxiosResponse<SuggestionsResponse> = await axios(
      `${LUIGIS_BOX_HOST}/v1/recommend`,
      {
        method: "POST",
        params: {
          tracker_id: TRACKER_ID,
        },
        data: JSON.stringify([
          {
            recommendation_type: recommendationType,
            user_id: userId,
          },
        ]),
      }
    )

    return data.data
  })
}

export const useSearch = (
  search: string,
  limit = 10,
  page = 1,
  cart: Readonly<Cart>
): UseQueryResult<
  { products: PricedProduct[] | []; total_hits: number },
  unknown
> => {
  return useQuery(
    ["search", search, limit, page, cart?.id],
    async () => {
      if (!cart?.id) {
        return { products: [], total_hits: 0 }
      }

      const data: AxiosResponse<SearchResponse> = await axios(
        `${LUIGIS_BOX_HOST}/search`,
        {
          method: "GET",
          params: {
            q: search,
            tracker_id: TRACKER_ID,
            size: limit,
            f: ["type:product"],
            page,
          },
        }
      )

      if (!data.data?.results?.hits?.length) {
        return { products: [], total_hits: 0 }
      }

      const { data: MedusaRes } = await medusaRequest(
        "GET",
        `store/products?id[]=${data.data.results.hits.map(
          (hit) => hit.url
        )}&expand=categories,variants,color,variants.prices,type&region_id=${
          cart.region_id
        }&cart_id=${cart.id}&currency_code=${cart.region.currency_code}`
      )

      if (!MedusaRes.products.length) {
        return { products: [], total_hits: 0 }
      }

      if (data.data.results.hits.length !== MedusaRes.products.length) {
        // find what is different
        const missingHits = data.data.results.hits.filter(
          (hit) => !MedusaRes.products.find((product) => product.id === hit.url)
        )

        console.log("Missing hits", missingHits)
      }

      return {
        products: data.data.results.hits
          .map((hit) => {
            const product = MedusaRes.products.find((p) => p.id === hit.url)

            if (!product) {
              return null
            }

            // Filter data
            const colors = product.color?.color_group?.map((c) => ({
              label: c,
              value: kebabCase(c),
            }))

            const pattern = {
              label: product.color?.pattern,
              value: kebabCase(product.color?.pattern),
            }

            const primaryCollection = product.categories.find(
              (c) => c.id === product.primary_category_id
            )
            const fabricLabel = primaryCollection?.metadata?.fabric ?? ""

            const fabric = {
              label: fabricLabel,
              value: kebabCase(fabricLabel),
            }

            const min = getMinimumPrice(product.variants)
            const max = getMaximumPrice(product.variants)

            return {
              colors,
              pattern,
              fabric,
              priceMin: min,
              priceMax: max,
              ...product,
            }
          })
          .filter(Boolean),
        total_hits: data.data.results.total_hits,
      }
    },
    {
      enabled: Boolean(search),
    }
  )
}

export const useSearchNoResultsSuggestedProducts = (
  region_id: string,
  options?: UseQueryOptions
): UseQueryResult<Product[], unknown> => {
  const { data, ...rest } = useQuery(
    ["search-no-results-suggested-products", region_id],
    async () => {
      const { data }: { data: SearchNoResultsSuggestedProductsResponse } =
        await medusaRequest(
          "GET",
          `store/search-suggested-products?region_id=${region_id}`
        )
      return data.suggested_products
    },
    options
  )

  return { data, ...rest }
}
