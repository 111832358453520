import { ProductCategory, ProductType } from "@medusajs/medusa"

export const isBedding = (
  product:
    | { type?: Pick<ProductType, "value">; categories: ProductCategory[] }
    | undefined
) => {
  if (!product?.type?.value) {
    return false
  }

  if (product?.categories?.some((i) => i.handle === "bedroom")) {
    return true
  }

  if (
    isDuvet(product) ||
    isFlatSheet(product) ||
    isFittedSheet(product) ||
    isPillow(product)
  ) {
    return true
  }

  return false
}

export const isFittedSheet = (
  product: { type?: Pick<ProductType, "value"> } | undefined
) => {
  if (!product?.type?.value) {
    return false
  }

  if (product.type.value.toLowerCase() === "fitted sheet") {
    return true
  }

  return false
}

export const isFlatSheet = (
  product: { type?: Pick<ProductType, "value"> } | undefined
) => {
  if (!product?.type?.value) {
    return false
  }

  if (product.type.value.toLowerCase() === "flat sheet") {
    return true
  }

  return false
}

export const isDuvet = (
  product: { type?: Pick<ProductType, "value"> } | undefined
) => {
  if (!product?.type?.value) {
    return false
  }

  if (product.type.value.toLowerCase() === "duvet cover") {
    return true
  }

  return false
}

export const isPillow = (
  product: { type?: Pick<ProductType, "value"> } | undefined
) => {
  if (!product?.type?.value) {
    return false
  }

  if (product.type.value.toLowerCase() === "pillow sham") {
    return true
  }

  return false
}

export const isShortSleeve = (
  product: { type?: Pick<ProductType, "value"> } | undefined
) => {
  if (!product?.type?.value) {
    return false
  }

  if (product.type.value.toLowerCase() === "short-sleeved shirt") {
    return true
  }

  return false
}

export const isBlanket = (
  product: { type?: Pick<ProductType, "value"> } | undefined
) => {
  if (!product?.type?.value) {
    return false
  }

  if (product.type.value.toLowerCase() === "blanket") {
    return true
  }

  return false
}

export const isClassicBathrobe = (
  product: { type?: Pick<ProductType, "value"> } | undefined
) => {
  if (!product?.type?.value) {
    return false
  }

  if (product.type.value.toLowerCase() === "classic bathrobe") {
    return true
  }

  return false
}

export const isHoodedBathrobe = (
  product: { type?: Pick<ProductType, "value"> } | undefined
) => {
  if (!product?.type?.value) {
    return false
  }

  if (product.type.value.toLowerCase() === "hooded bathrobe") {
    return true
  }

  return false
}

export const isPants = (
  product: { type?: Pick<ProductType, "value"> } | undefined
) => {
  if (!product?.type?.value) {
    return false
  }

  if (product.type.value.toLowerCase() === "pants") {
    return true
  }

  return false
}

export const isShorts = (
  product: { type?: Pick<ProductType, "value"> } | undefined
) => {
  if (!product?.type?.value) {
    return false
  }

  if (product.type.value.toLowerCase() === "shorts") {
    return true
  }

  return false
}

export const isShirt = (
  product: { type?: Pick<ProductType, "value"> } | undefined
) => {
  if (!product?.type?.value) {
    return false
  }

  if (product.type.value.toLowerCase() === "long-sleeved shirt") {
    return true
  }

  return false
}

export const isSlippers = (
  product: { type?: Pick<ProductType, "value"> } | undefined
) => {
  if (!product?.type?.value) {
    return false
  }

  if (
    product.type.value.toLowerCase() === "nagoya shoes" ||
    product.type.value.toLowerCase() === "uji shoes"
  ) {
    return true
  }

  return false
}

export const isSleepwear = (
  product: { type?: Pick<ProductType, "value"> } | undefined
) => {
  if (!product?.type?.value) {
    return false
  }

  if (
    isShortSleeve(product) ||
    isPants(product) ||
    isShorts(product) ||
    isShirt(product) ||
    isSlippers(product)
  ) {
    return true
  }

  return false
}
