import * as React from "react"
import { Box, Heading, Paragraph } from "theme-ui"
import { z } from "zod"
import Medusa from "../../../services/api"
import { trackRestockSignup } from "../../../services/analytics"

// Hooks
import { useStore } from "../../../context/NewStoreContext"
import { useNotificationContext } from "../../../context/NotificationContext"

// Components
import { TextInlineButtonField } from "../form/TextInlineButtonField"
import { Drawer, DrawerProps } from "../ui/Drawer"
import { ProductVariant } from "@medusajs/medusa"
import { PricedVariant } from "@medusajs/medusa/dist/types/pricing"
import { Checkbox } from "../ui/Checkbox"
import { Link } from "../Link"

type NotifyMeDrawerProps = {
  selectedVariant: ProductVariant | PricedVariant
  isOpened: boolean
  isNotifyOpenedFromDrawer: boolean
  onCloseClick: () => void
  onBackdropClick?: () => void
} & DrawerProps

export const NotifyMeDrawer: React.FC<NotifyMeDrawerProps> = ({
  selectedVariant,
  isOpened,
  isNotifyOpenedFromDrawer,
  onCloseClick,
  onBackdropClick,
  ...rest
}) => {
  const { pushNotification, dismissNotification } = useNotificationContext()
  const { cart } = useStore()
  const [email, setEmail] = React.useState("")
  const emailSchema = z.string().email()
  const [hasConsent, setHasConsent] = React.useState(false)
  const [consentErrorMessage, setConsentErrorMessage] = React.useState("")
  const [signedUp, setSignedUp] = React.useState([])
  const [newsletterChecked, setNewsletterChecked] = React.useState(false)

  const handleNotifyMe = async () => {
    if (!hasConsent) {
      setConsentErrorMessage(
        "You must agree to the Terms and Conditions and Privacy Policy."
      )
      return
    }
    try {
      emailSchema.parse(email)
    } catch (error) {
      setConsentErrorMessage("Invalid email.")
      return
    }

    await Medusa.restock.signUp(selectedVariant?.id, email)

    trackRestockSignup(cart, selectedVariant, email)
    setSignedUp([...signedUp, selectedVariant?.id])

    if (isNotifyOpenedFromDrawer) {
      //setTimeout(() => setOpenedDrawer("size-picker"), 1000)
      //setIsNotifyOpenedFromDrawer(false)
      onCloseClick()
    }

    if (newsletterChecked) {
      const payload = {
        email: email,
        ids: {},
        source: "main_notify_me",
      }

      Medusa.newsletter
        .signup(payload)
        .then(({ data }) => {
          if (data.user_exists) {
            pushNotification({
              id: "sign_up_complete",
              body: "This email is already subscribed",
              dismiss: {
                duration: 3000,
              },
            })
          } else {
            pushNotification({
              id: "sign_up_complete",
              body: "Success",
              dismiss: {
                duration: 3000,
              },
            })
          }
        })
        .catch(() => {
          pushNotification({
            id: "sign_up_failed",
            body: "Sorry, something went wrong. Make sure the input is correct and try again",
            dismiss: {
              duration: 3000,
            },
          })
        })
    }
  }
  return (
    <Drawer
      {...rest}
      isOpened={isOpened}
      size="lg"
      onCloseClick={() => onCloseClick()}
      onBackdropClick={() => onBackdropClick()}
      onSwipeRight={() => onCloseClick()}
    >
      <Paragraph sx={{ fontSize: "md", marginBlockEnd: 9 }}>
        Notify me when the product is available
      </Paragraph>
      <TextInlineButtonField
        inputProps={{
          type: "email",
          placeholder: "Email",
          value: email,
          onChange: (e) => setEmail(e.target.value),
          sx: { flex: 1 },
        }}
        buttonProps={{
          children: "Submit",
          isSuccessful: signedUp.includes(selectedVariant?.id),
          isVisuallyDisabled:
            signedUp.includes(selectedVariant?.id) || !hasConsent,
          disabled: signedUp.includes(selectedVariant?.id),
          onClick: handleNotifyMe,
        }}
        sx={{ marginBlockEnd: 6 }}
      />
      <Box sx={{ position: "relative" }}>
        <Box
          sx={{
            opacity: signedUp.includes(selectedVariant?.id) ? 0 : 1,
            visibility: signedUp.includes(selectedVariant?.id)
              ? "hidden"
              : "visible",
            transition: "opacity .2s, visibility .2s",
          }}
        >
          <Checkbox
            label="Sign me up for Tekla Fabrics news & digital communications"
            labelStyle={{ fontSize: "xs", marginBlockEnd: 4 }}
            onChange={() => setNewsletterChecked((prev) => !prev)}
          />
          <Checkbox
            label={
              <>
                I confirm that I have read and understood the{" "}
                <Link variant="underline" to="/terms-and-conditions">
                  Terms and Conditions
                </Link>{" "}
                and the{" "}
                <Link variant="underline" to="/privacy-policy">
                  Privacy Policy
                </Link>
              </>
            }
            labelStyle={{ fontSize: "xs" }}
            onChange={() => setHasConsent((prev) => !prev)}
            errorMessage={consentErrorMessage}
            errorMessageStyle={{ fontSize: "xs" }}
          />
        </Box>
        <Paragraph
          sx={{
            width: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            color: "grayscale.600",
            fontSize: "sm",
            visibility: signedUp.includes(selectedVariant?.id)
              ? "visible"
              : "hidden",
            opacity: signedUp.includes(selectedVariant?.id) ? 1 : 0,
            transition: "opacity .2s, visibility .2s",
          }}
        >
          {/* TODO: Translation string or use existing one with product name */}
          You will receive an email as soon as the product is restocked
        </Paragraph>
      </Box>
    </Drawer>
  )
}
