import { Product, ProductVariant, Region } from "@medusajs/medusa"
import { EnrichedMedusaVariant } from "gatsby-node"

export const getRecommendedSizes = (
  region: Region,
  productTypeId: string,
  variants: (ProductVariant | EnrichedMedusaVariant)[]
) => {
  if (!variants?.length) {
    return []
  }

  const recommendedInRegion =
    region?.metadata?.recommended_sizes?.[productTypeId]?.map((i) =>
      i.toLowerCase()
    ) || []

  return variants.reduce((acc, next) => {
    if (recommendedInRegion?.includes(next.title?.toLowerCase())) {
      acc.push(next)
    }
    return acc
  }, [])
}
