import { ProductVariant } from "@medusajs/medusa"
import { PricedVariant } from "@medusajs/medusa/dist/types/pricing"
import { EnrichedMedusaVariant } from "gatsby-node"

export const getMaximumPrice = (
  variants: ProductVariant[] | PricedVariant[] | EnrichedMedusaVariant[]
) => {
  if (!variants?.length) {
    return
  }

  return Math.max(
    // @ts-ignore
    ...variants?.map((i) => i && i.calculated_price_incl_tax)
  )
}
