import * as React from "react"
import {
  Box,
  Image,
  Flex,
  Heading,
  Paragraph,
  ThemeUIStyleObject,
} from "theme-ui"

import { Link } from "../Link"
import { NumberInput } from "../ui/NumberInput"
import { formatPrices } from "../../../utils/prices"
import { getSizeAcronym } from "../../../utils/functions/getSizeAcronym"
import { useStore } from "../../../context/NewStoreContext"
import type { RemoveItemProps, DisplayItem } from "types"
import { useInterfaceContext } from "../../../context/InterfaceContext"
import { isBoxersItem } from "../../../utils/is-boxers-item"
import { PricedProduct } from "@medusajs/medusa/dist/types/pricing"
import { getTitle } from "../utils/product/getTitle"
import { Icon } from "../ui/Icon"

export const CartProduct: React.FC<{
  item: DisplayItem
  medusaProduct: PricedProduct
  clear: (item: DisplayItem) => void
  onAddToWishlist: (object) => void
  onRemoveLine: (object: RemoveItemProps) => void
  isSoldOut?: boolean
  hasQuantityChanged: boolean
  color?: string
  sx?: ThemeUIStyleObject
  onOOSRemove?: () => void
}> = ({
  item,
  clear,
  onAddToWishlist,
  onRemoveLine,
  isSoldOut,
  hasQuantityChanged,
  color,
  sx,
  medusaProduct,
  onOOSRemove,
}) => {
  const { cart, updateLineItem } = useStore()

  let price =
    item?.original_total && cart?.region?.name !== "United States"
      ? formatPrices(cart, item.original_total, 2)
      : formatPrices(cart, item.subtotal, 2)
  let discountedPrice =
    item?.total !== item?.original_total && !item?.is_bundle
      ? formatPrices(cart, item.total, 2)
      : ""

  if (cart?.region?.name === "United States") {
    discountedPrice = item.discount_total
      ? formatPrices(cart, item.subtotal - item.discount_total, 2)
      : ""
  }

  const { toggleCart } = useInterfaceContext()
  if (cart?.region?.name === "United States") {
    discountedPrice = item.discount_total
      ? formatPrices(cart, item.subtotal - item.discount_total, 2)
      : ""
  }

  let size = item.variant.title

  if (
    cart?.region?.name === "United States" &&
    typeof item?.metadata?.sizeInInches === "string"
  ) {
    size = item.metadata.sizeInInches
  }

  if (
    item?.variant?.sku?.startsWith("TT") &&
    typeof item?.metadata?.type === "string"
  ) {
    size = item.metadata.type
  }

  if (typeof item?.metadata?.customSize === "string") {
    size = item.metadata.customSize
  }

  const itemInventoryQuantity = medusaProduct?.variants?.find(
    (v) => v.id === item.variant_id
  )?.inventory_quantity

  const collection = medusaProduct?.categories?.find((c) =>
    Boolean(c.parent_category_id)
  )

  const productTitle = getTitle(
    {
      sku: item.variant.sku,
      title: medusaProduct?.title,
      color: undefined,
      isGiftcard: item.is_giftcard,
      type: medusaProduct?.type,
      metadata: item.metadata as unknown as Record<string, string>,
    },
    collection
  )

  return (
    <>
      {!item?.isRemoving &&
        (isSoldOut ? (
          <Flex
            sx={{
              borderBottom: "1px solid",
              borderBottomColor: "grayscale.100",
              paddingBlockStart: 6,
              paddingBlockEnd: 6,
              ...sx,
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Flex>
                <Box>
                  <Link
                    to={`/product/${item?.variant?.product?.handle}`}
                    sx={{ display: "block", marginInlineEnd: 6 }}
                    onClick={toggleCart}
                  >
                    <Image
                      src={
                        typeof item?.metadata?.image === "string" &&
                        item?.metadata?.image
                          ? item.metadata.image
                          : item.thumbnail
                      }
                      alt={item.title}
                      sx={{ display: "block", width: 33 }}
                    />
                  </Link>
                </Box>
                <Flex
                  sx={{
                    flexDirection: "column",
                    flex: 1,
                    justifyContent: "space-between",
                    marginInlineEnd: [0, 6],
                  }}
                >
                  <Box>
                    <Heading
                      as="h4"
                      sx={{
                        fontSize: "sm",
                        marginBlockEnd: [0, 2],
                        "a, button": {
                          color: "grayscale.600",
                          textDecoration: "line-through !important",
                        },
                      }}
                    >
                      <Link to={`/product/${item?.variant?.product?.handle}`}>
                        {productTitle}
                      </Link>
                    </Heading>
                    <Box sx={{ color: "grayscale.600", marginBlockEnd: 4 }}>
                      {item.is_bundle ? (
                        <Paragraph
                          sx={{
                            fontSize: ["xs", "sm"],
                            textDecoration: "line-through",
                          }}
                        >
                          {`${item.first_product_label}`}
                          <br />
                          {`${item.second_product_label}`}
                        </Paragraph>
                      ) : (
                        color && (
                          <Paragraph
                            sx={{
                              fontSize: "xs",
                              textDecoration: "line-through",
                            }}
                          >
                            {"Colour: " + color}
                          </Paragraph>
                        )
                      )}
                      {!item.is_giftcard && !item.is_bundle && size && (
                        <Paragraph
                          sx={{
                            fontSize: "xs",
                            textDecoration: "line-through",
                          }}
                        >
                          {"Size: " + getSizeAcronym(size)}
                        </Paragraph>
                      )}
                    </Box>
                    <Box>
                      <Paragraph sx={{ color: "red" }}>Sold out</Paragraph>
                      <Paragraph>
                        This product has been removed from your cart
                      </Paragraph>
                    </Box>
                  </Box>
                  <Flex sx={{ gap: 5, fontSize: ["sm", "xs"] }}>
                    <Link
                      onClick={() => {
                        onAddToWishlist(item)
                      }}
                    >
                      Move to wishlist
                    </Link>
                  </Flex>
                </Flex>
                <Box
                  sx={{
                    display: "block",
                    textAlign: "right",
                    marginInlineStart: 5,
                    cursor: "pointer",
                    marginBlockStart: 1,
                  }}
                  onClick={() => {
                    if (onOOSRemove) {
                      onOOSRemove()
                    }
                  }}
                >
                  <Icon name="x" size="3" />
                </Box>
              </Flex>
            </Box>
          </Flex>
        ) : (
          <Flex
            sx={{
              borderBottom: "1px solid",
              borderBottomColor: "grayscale.100",
              paddingBlockStart: 6,
              paddingBlockEnd: 6,
              ...sx,
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Flex>
                <Box>
                  <Link
                    to={`/product/${item?.variant?.product?.handle}`}
                    sx={{ display: "block", marginInlineEnd: 6 }}
                    onClick={toggleCart}
                  >
                    <Image
                      src={
                        typeof item?.metadata?.image === "string" &&
                        item?.metadata?.image
                          ? item.metadata.image
                          : item.thumbnail
                      }
                      alt={item.title}
                      sx={{ display: "block", width: 33 }}
                    />
                  </Link>
                </Box>
                <Flex
                  sx={{
                    flexDirection: "column",
                    flex: 1,
                    justifyContent: "space-between",
                    marginInlineEnd: [0, 6],
                  }}
                >
                  <Box>
                    <Heading
                      as="h4"
                      sx={{
                        fontSize: "sm",
                        marginBlockEnd: [0, 4],
                        a: { color: "primary", textDecoration: "none" },
                      }}
                    >
                      <Link to={`/product/${item?.variant?.product?.handle}`}>
                        {productTitle}
                      </Link>
                    </Heading>
                    <Box sx={{ display: ["block", "none"], marginBlockEnd: 2 }}>
                      {discountedPrice ? (
                        <Paragraph
                          sx={{
                            color: "grayscale.600",
                            textDecoration: "line-through",
                          }}
                        >
                          {price}
                        </Paragraph>
                      ) : null}
                      <Paragraph>
                        {discountedPrice ? discountedPrice : price}
                      </Paragraph>
                      {!item.allow_discounts &&
                        cart?.items?.some((e) => e.allow_discounts === true) &&
                        !!cart?.discount_total && (
                          <Paragraph sx={{ fontSize: "xs" }}>
                            Discount not applicable
                          </Paragraph>
                        )}
                    </Box>
                    <Box sx={{ marginBlockEnd: 4 }}>
                      {item.is_giftcard && (
                        <Paragraph
                          sx={{
                            fontSize: "xs",
                          }}
                        >
                          {!item.metadata?.is_printed
                            ? "Type: Digital Card"
                            : "Type: Physical Card"}
                        </Paragraph>
                      )}
                      {item.is_bundle ? (
                        <Paragraph sx={{ fontSize: ["xs", "sm"] }}>
                          {`${item.first_product_label}`}
                          <br />
                          {`${item.second_product_label}`}
                        </Paragraph>
                      ) : (
                        color && (
                          <Paragraph sx={{ fontSize: "xs" }}>
                            {"Colour: " + color}
                          </Paragraph>
                        )
                      )}
                      {!item.is_giftcard && !item.is_bundle && size && (
                        <Paragraph sx={{ fontSize: "xs" }}>
                          {"Size: " + getSizeAcronym(size)}
                        </Paragraph>
                      )}
                    </Box>
                    <Box sx={{ marginBlockEnd: 4 }}>
                      {item.is_bundle ? (
                        <Paragraph sx={{ fontSize: ["xs", "sm"] }}>
                          Quantity: 1
                        </Paragraph>
                      ) : (
                        <Flex
                          sx={{
                            flexDirection: ["column", "row"],
                            gap: [2, 6],
                            alignItems: "flex-start",
                          }}
                        >
                          <Flex sx={{ alignItems: "center", gap: 4 }}>
                            <Box sx={{ maxWidth: 22 }}>
                              <NumberInput
                                value={item.quantity}
                                min={0}
                                max={
                                  medusaProduct?.variants?.find(
                                    (v) => v.id === item.variant_id
                                  )?.allow_backorder
                                    ? Infinity
                                    : itemInventoryQuantity
                                }
                                onNumberChange={(value) => {
                                  updateLineItem.mutateAsync({
                                    lineId: item.id,
                                    quantity: value,
                                  })
                                }}
                                addDisabled={
                                  item.quantity === itemInventoryQuantity
                                }
                                inputSx={hasQuantityChanged && { color: "red" }}
                              />
                            </Box>
                            {hasQuantityChanged ? (
                              <Paragraph sx={{ fontSize: "xs", color: "red" }}>
                                Quantity adjusted due to low stock
                              </Paragraph>
                            ) : null}
                          </Flex>
                          {item.quantity === itemInventoryQuantity && (
                            <Paragraph
                              sx={{
                                fontSize: "xs",
                                textAlign: "center",
                                minWidth: "104px",
                                backgroundColor: "grayscale.300",
                                paddingBlock: 1,
                                paddingInline: 2,
                              }}
                            >
                              Only {itemInventoryQuantity} in stock
                            </Paragraph>
                          )}
                        </Flex>
                      )}
                    </Box>
                  </Box>
                  <Flex sx={{ gap: 5, fontSize: ["sm", "xs"] }}>
                    <Link
                      onClick={() => {
                        onRemoveLine({
                          id: item.id,
                          ids: item.ids,
                          variant: item.variant,
                          quantity: item.quantity,
                          unit_price: item.original_total,
                          is_bundle: item.is_bundle,
                          bundle: item,
                        })
                      }}
                    >
                      Remove
                    </Link>
                    <Link onClick={() => onAddToWishlist(item)}>
                      Move to wishlist
                    </Link>
                  </Flex>
                </Flex>
                <Box
                  sx={{
                    display: ["none", "block"],
                    textAlign: "right",
                    marginInlineStart: "auto",
                  }}
                >
                  {discountedPrice ? (
                    <Paragraph
                      sx={{
                        color: "grayscale.600",
                        textDecoration: "line-through",
                      }}
                    >
                      {price}
                    </Paragraph>
                  ) : null}
                  <Paragraph>
                    {discountedPrice ? discountedPrice : price}
                  </Paragraph>
                  {!item.allow_discounts &&
                    cart?.items?.some((e) => e.allow_discounts === true) &&
                    !!cart?.discount_total && (
                      <Paragraph sx={{ fontSize: "xs" }}>
                        Discount not applicable
                      </Paragraph>
                    )}
                </Box>
              </Flex>
              {isBoxersItem(item.variant.sku) ? (
                <Flex
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: 7,
                    color: "primary",
                    fontSize: "xs",
                    textAlign: "center",
                    backgroundColor: "grayscale.300",
                    paddingBlock: 1,
                    paddingInline: 4,
                    marginBlockStart: 5,
                  }}
                >
                  <Paragraph>
                    Product non-returnable for hygiene reasons.
                  </Paragraph>
                </Flex>
              ) : null}
            </Box>
          </Flex>
        ))}
      {item?.isRemoving && (
        <Flex
          sx={{
            ...sx,
            justifyContent: "space-between",
            alignItems: "baseline",
            gap: 5,
            borderBlockEnd: "1px solid",
            borderBlockEndColor: "grayscale.100",
            paddingBlockStart: 6,
            paddingBlockEnd: 6,
          }}
        >
          <Paragraph>{item.label}</Paragraph>
          <Link onClick={() => clear(item)} sx={{ flexShrink: 0 }}>
            Undo
          </Link>
        </Flex>
      )}
    </>
  )
}
