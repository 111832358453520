// External packages
import * as React from "react"
import {
  Grid,
  Box,
  GridProps,
  BoxProps,
  Paragraph,
  Heading,
  Flex,
  Text,
  Image,
  Input,
} from "theme-ui"
import { ProductCategory } from "@medusajs/medusa"
import { useProducts } from "medusa-react"
import GatsbyLink from "gatsby-link"
import { PageProps } from "gatsby"
import { IGatsbyImageData } from "gatsby-plugin-image"
import { Shopstory } from "@shopstory/core/react"
import { navigate } from "gatsby"
import * as Accordion from "@radix-ui/react-accordion"
import * as RadioGroup from "@radix-ui/react-radio-group"
import * as Checkbox from "@radix-ui/react-checkbox"
import * as Slider from "@radix-ui/react-slider"
import { useLocation } from "@reach/router"
import { capitalize } from "lodash"
import useDetectScroll from "@smakss/react-scroll-direction"

// Analytics
import { trackFilterProducts } from "../../services/analytics"

// Contexts
import { TeklaShopstoryProvider } from "../../../src/shopstory/Provider"
import { useStore } from "../../context/NewStoreContext"
import { useAccountContext } from "../../context/AccountContext"

// Utilities
import { sortSizes } from "../../../src/utils/sortSizes"
import { sortByRank } from "../../../src/utils/sort-by-rank"
import { getSizeAmericanValue } from "../../../src/utils/getSizeAmericanValue"
import { isPurchasable } from "../../../src/components/v2/utils/product/isPurchasable"
import { formatMoneyAmount } from "../../utils/prices"

// Hooks
import useLockedBody from "../../hooks/v2/useLockedBody"
import useLockedBodyMobile from "../../hooks/v2/useLockedBodyMobile"
import usePagination from "../../hooks/v2/usePagination"
import { useProductsPriceRange } from "../../hooks/v2/useProductsPriceRange"
import { useProductsBestsellers } from "../../hooks/v2/useProductsBestsellers"
import { useProductsVariantsInventory } from "../../hooks/v2/useProductsVariantsInventory"

// Components
import {
  CategoryPageProduct,
  CategoryPageWidget,
  ContentfulWidget,
} from "gatsby-node"
import Layout from "../../components/layouts/layout"
import { Link, LinkProps } from "../../components/v2/Link"
import { Button } from "../../components/v2/Button"
import { UiProgress } from "../../components/v2/ui/Progress"
import { Icon } from "../../components/v2/ui/Icon"
import { Drawer } from "../../components/v2/ui/Drawer"
import {
  UiAccordionContent,
  UiAccordionHeader,
  UiAccordionItem,
  UiAccordionTrigger,
} from "../../components/v2/ui/Accordion"
import {
  UiRadioGroupField,
  UiRadioGroupItem,
  UiRadioGroupLabel,
} from "../../components/v2/ui/RadioGroup"
import {
  UiRadixCheckbox,
  UiRadixCheckboxIcon,
  UiRadixCheckboxLabel,
} from "../../components/v2/ui/RadixCheckbox"
import {
  UiSlider,
  UiSliderRange,
  UiSliderThumb,
  UiSliderTrack,
} from "../../components/v2/ui/Slider"
import SEO from "../../components/seo"
import { FeaturedProductsWidget } from "./CategoryLandingPageTemplate"

interface CategoryTemplateProps {
  category: Pick<
    ProductCategory,
    "id" | "handle" | "name" | "description" | "parent_category"
  >
  products: CategoryPageProduct[]
  widgets: CategoryPageWidget[]
  seo: {
    canonicalUrl: string
    description: string
    metaImageUrl: string
    breadcrumbsList: {
      "@type": string
      position: number
      name: string
      item: string
    }[]
  }
  shopstoryContent: any | undefined
}

type Data =
  | ({
      variant: "product"
      priceMin: number | undefined
      priceMax: number | undefined
    } & CategoryPageProduct)
  | ({ variant: "widget" } & CategoryPageWidget)

export interface Filter {
  color: {
    value: string
    label: string
  }[]
  fabric: {
    value: string
    label: string
  }[]
  pattern: {
    value: string
    label: string
  }[]
  product: {
    value: string
    label: string
    metadata: {
      [key: string]: string
    }
  }[]
  size: {
    value: string
    label: string
  }[]
  price?: {
    min: number
    max: number
  }
}

export interface SelectedFilters {
  color: string[]
  fabric: string[]
  pattern: string[]
  product: string[]
  size: string[]
  price_min: string
  price_max: string
}

type Product = CategoryPageProduct & {
  priceMin: number
  priceMax: number
}

// Check if size has dimensions (e.g. 50x70, 100x70x90)
export const isDimensionalSize = (size: string) => {
  return /^\d{1,3}x\d{1,3}(x\d{1,3})?$/.test(size)
}

export const getFilterParam = (key: string, searchParams: URLSearchParams) => {
  const param = searchParams.get(key)
  if (param) {
    return param.split(",")
  }
  return []
}

// Filter products
export const filterProducts = (
  products: Product[],
  selectedFilters: SelectedFilters,
  sizesInStock?: string[]
) => {
  let filteredProducts = products
  if (
    !selectedFilters.price_min &&
    !selectedFilters.price_max &&
    !Object.values(selectedFilters).some((filter) => Boolean(filter?.length))
  ) {
    return products
  }

  // Filter by product type
  if (selectedFilters?.product?.length > 0) {
    filteredProducts = filteredProducts?.filter((product) => {
      return selectedFilters.product.includes(product.type.pluralValue)
    })
  }

  // Filter by color
  if (selectedFilters?.color?.length > 0) {
    filteredProducts = filteredProducts?.filter((product) => {
      return product.colors?.some((color) =>
        selectedFilters.color.includes(color.value)
      )
    })
  }

  // Filter by fabric
  if (selectedFilters.fabric?.length > 0) {
    filteredProducts = filteredProducts?.filter((product) => {
      return selectedFilters.fabric.includes(product.fabric.value)
    })
  }

  // Filter by pattern
  if (selectedFilters.pattern?.length > 0) {
    filteredProducts = filteredProducts?.filter((product) => {
      return selectedFilters.pattern.includes(product.pattern.value)
    })
  }

  // Filter by size
  if (selectedFilters.size?.length > 0) {
    filteredProducts = filteredProducts?.filter((product) => {
      return product.sizes?.some((size) => {
        return sizesInStock?.length
          ? sizesInStock.includes(size.variant_id.toString()) &&
              selectedFilters.size.includes(size.value)
          : selectedFilters.size.includes(size.value)
      })
    })
  }

  // Filter by price
  if (selectedFilters.price_min && selectedFilters.price_max) {
    filteredProducts = filteredProducts?.filter((product) => {
      const priceMin = product.priceMin / 100
      const priceMax = product.priceMax / 100
      const selectedMin = parseInt(selectedFilters.price_min)
      const selectedMax = parseInt(selectedFilters.price_max)

      return (
        (priceMin >= selectedMin || priceMax >= selectedMin) &&
        (priceMin <= selectedMax || priceMax <= selectedMax)
      )
    })
  }

  return filteredProducts
}

// Get filter data from products
const getFilterData = (
  products: Product[],
  isUSA?: boolean,
  isSubcategory?: boolean
) => {
  let filter: Filter = {
    color: [],
    fabric: [],
    pattern: [],
    product: [],
    size: [],
  }

  if (!products?.length) {
    return filter
  }

  // If it is parent category, include price filter
  if (!isSubcategory && products?.[0].priceMin) {
    const minPrice = Math.min(...products.map((item) => item.priceMin))
    const maxPrice = Math.max(...products.map((item) => item.priceMax))

    filter = {
      ...filter,
      price: {
        min: minPrice / 100,
        max: maxPrice / 100,
      },
    }
  }

  products.forEach((product) => {
    const { pattern, type, fabric, colors } = product

    // Color filter
    colors?.forEach((color) => {
      if (color && !filter.color.some((i) => i.value === color.value)) {
        filter.color.push({
          value: color.value,
          label: capitalize(color.label),
        })
      }
    })

    // Pattern filter
    if (
      pattern?.label &&
      !filter.pattern.some((i) => i.value === pattern?.value)
    ) {
      filter.pattern.push({
        value: pattern.value,
        label: capitalize(pattern.label),
      })
    }

    // Product filter
    if (
      type?.label &&
      !filter.product.some((i) => i.value === type?.pluralValue)
    ) {
      filter.product.push({
        value: type.pluralValue,
        label: type.pluralLabel,
        metadata: type.metadata,
      })
    }

    // Fabric filter
    if (
      fabric?.label &&
      !filter.fabric.some((i) => i.value === fabric?.value)
    ) {
      filter.fabric.push(fabric)
    }

    // Size filter
    product.sizes?.forEach((size) => {
      if (!filter.size.some((i) => i.value === size.value)) {
        filter.size.push({
          value: size.value,
          label:
            isUSA && isDimensionalSize(size.label)
              ? getSizeAmericanValue(size.label)
              : size.label,
        })
      }
    })
  })

  // Sort filter data
  filter.color = filter.color.sort((a, b) => a.label?.localeCompare(b.label))
  filter.pattern = filter.pattern.sort((a, b) =>
    a.label?.localeCompare(b.label)
  )
  filter.fabric = filter.fabric.sort((a, b) => a.label?.localeCompare(b.label))
  filter.product.sort((a, b) => a.label?.localeCompare(b.label))
  // Sort sizes
  filter.size = sortSizes(filter.size)

  return filter
}

export const getSelectedFiltersNumber = (
  selectedFilters: SelectedFilters
): number => {
  const number = Object.values(selectedFilters).filter(
    (values) => Array.isArray(values) && values.length > 0
  ).length

  if (
    (selectedFilters["price_min"] && selectedFilters["price_min"] !== null) ||
    (selectedFilters["price_max"] && selectedFilters["price_max"] !== null)
  ) {
    return number + 1
  }

  return number
}

export const handlePageChange = (page: number) => {
  const pageStr = page.toString()
  const queryParams = new URLSearchParams(location.search)
  queryParams.set("page", pageStr)

  navigate(`${location.pathname}?${queryParams.toString()}`, {
    state: { disableScrollToTop: true },
  })
}

const SORT_OPTIONS = [
  {
    label: "Latest arrivals",
    value: "latest-arrivals",
  },
  {
    label: "Price (Low to high)",
    value: "price-low-to-high",
  },
  {
    label: "Bestsellers",
    value: "bestsellers",
  },
  {
    label: "Price (High to low)",
    value: "price-high-to-low",
  },
]

const CategoryTemplate = ({
  pageContext,
}: PageProps<object, CategoryTemplateProps>) => {
  const { category, products, widgets, shopstoryContent, seo } = pageContext
  const categoryGridRef = React.useRef(null)
  const filterNavigationAnchorRef = React.useRef(null)
  const filterButtonTopTriggerPointRef = React.useRef(null)
  const filterButtonBottomTriggerPointRef = React.useRef(null)
  const filterFloatingButtonRef = React.useRef(null)
  const quickFiltersRef = React.useRef(null)
  const categoryStickyBarRef = React.useRef(null)
  const { cart } = useStore()
  const location = useLocation()
  const { properties } = useAccountContext()
  const { scrollDir, scrollPosition } = useDetectScroll()

  const searchParams = new URLSearchParams(location.search)
  const [selectedFilters, setSelectedFilters] =
    React.useState<SelectedFilters>("")
  const [selectedSort, setSelectedSort] = React.useState<string>()

  React.useEffect(() => {
    setSelectedFilters({
      color: getFilterParam("color", searchParams),
      fabric: getFilterParam("fabric", searchParams),
      pattern: getFilterParam("pattern", searchParams),
      product: getFilterParam("product", searchParams),
      size: getFilterParam("size", searchParams),
      price_min: searchParams.get("price_min"),
      price_max: searchParams.get("price_max"),
    })
    setSelectedSort(searchParams.get("sort"))
  }, [location.search])

  // Get price range
  const { data: prices, refetch: refetchPrices } = useProductsPriceRange(
    category.id,
    cart?.region_id,
    cart?.region?.currency_code
  )

  const { data: bestsellers } = useProductsBestsellers(cart?.region_id, {
    enabled: Boolean(cart?.region_id) && selectedSort === "bestsellers",
    keepPreviousData: true,
  })

  // Exclude internal archive products for logged out user
  const purchasableProducts = React.useMemo(() => {
    const purchasable = products?.filter((product) =>
      isPurchasable(product?.sku, properties)
    )
    if (!prices) {
      return purchasable
    }
    return purchasable.map((p) => {
      return {
        ...p,
        priceMin: prices?.find((price) => price.id === p.id)?.min,
        priceMax: prices?.find((price) => price.id === p.id)?.max,
      }
    })
  }, [products, properties, prices])

  const filterBySize = (products: Product[]) => {
    let filteredProducts = []
    if (selectedFilters.size?.length > 0) {
      filteredProducts = products?.filter((product) => {
        return product.sizes?.some((size) =>
          selectedFilters.size.includes(size.value)
        )
      })
    }

    return filteredProducts
  }

  const filteredBySize = filterBySize(purchasableProducts)

  const { data: variantsInventory } = useProductsVariantsInventory(
    filteredBySize.flatMap((product) =>
      product.sizes
        .filter((size) => selectedFilters.size?.includes(size.value))
        .map((size) => size.variant_id)
    ),
    {
      enabled: filteredBySize?.length > 0 && selectedFilters.size?.length > 0,
    }
  )

  const sortProducts = (products: Product[]) => {
    if (!selectedSort) {
      return products
    }

    if (selectedSort === "latest-arrivals") {
      return products.sort((a, b) => {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      })
    }

    if (selectedSort === "price-low-to-high") {
      return products.sort((a, b) => {
        return a.priceMin - b.priceMin
      })
    }

    if (selectedSort === "price-high-to-low") {
      return products.sort((a, b) => {
        return b.priceMax - a.priceMax
      })
    }

    if (selectedSort === "bestsellers" && bestsellers?.length > 0) {
      return products.sort((a, b) => {
        const aIsBestseller = bestsellers.includes(a?.id)
        const bIsBestseller = bestsellers.includes(b?.id)

        if (aIsBestseller && bIsBestseller) {
          return bestsellers.indexOf(a.id) - bestsellers.indexOf(b.id)
        } else if (aIsBestseller) {
          return -1
        } else if (bIsBestseller) {
          return 1
        } else {
          return 0
        }
      })
    }

    return products
  }
  // Set grid data
  const gridData = () => {
    let data: Data[] = []
    if (!selectedFilters.product) {
      return []
    }

    let filteredProducts = filterProducts(
      purchasableProducts,
      selectedFilters,
      variantsInventory
    )

    filteredProducts = sortProducts(filteredProducts)
    data = filteredProducts?.map((product, i) => {
      let image
      let imageOnHover
      if (
        Boolean(selectedFilters.product?.length) &&
        product.productTypePageImage?.female &&
        product.productTypePageImageOnHover?.male
      ) {
        image =
          i % 2 === 0
            ? product.productTypePageImage?.female
            : product.productTypePageImage?.male
        imageOnHover =
          i % 2 === 0
            ? product.productTypePageImageOnHover?.female
            : product.productTypePageImageOnHover?.male
      }
      return {
        ...product,
        variant: "product",
        image: image || product.image,
        imageOnHover: imageOnHover || product.imageOnHover,
      }
    })

    // Check if any filter is applied, apart from product type
    const isFilterApplied = Object.keys(selectedFilters).some(
      (key) => key !== "product" && selectedFilters[key]?.length > 0
    )

    const productType = selectedFilters.product?.[0]

    // Filter widgets by product type
    const widgetsFiltered = productType
      ? widgets?.filter(
          (widget) => widget.productType && widget.productType === productType
        )
      : widgets.filter((widget) => !widget.productType)

    if (widgetsFiltered?.length > 0 && !isFilterApplied) {
      const sortedWidgets = widgetsFiltered.sort(
        (a, b) => a.displayAfterNthItem - b.displayAfterNthItem
      )
      sortedWidgets.forEach((widget) => {
        if (data.length >= widget.displayAfterNthItem) {
          data.splice(widget.displayAfterNthItem, 0, {
            ...widget,
            variant: "widget",
          })
        }
      })
    }
    return data
  }

  const getRows = (element: HTMLElement[]) => {
    const tolerance = 1
    const rows = new Array()

    // Group elements by their bottom position
    element.forEach((element) => {
      // Get the bottom position of the element
      const bottom = element.offsetTop + element.offsetHeight
      // Find a row with the same bottom position
      const sameRowArray = rows.find((row) => {
        const rowBottom = row[0].offsetTop + row[0].offsetHeight
        return Math.floor(Math.abs(rowBottom - bottom)) <= tolerance
      })

      // If a row with the same bottom position exists and element is not a
      // widget, add the element to that row, otherwise create a new row
      if (element.dataset.textual !== "true") {
        if (rows.length > 0 && Boolean(sameRowArray)) {
          sameRowArray.push(element)
        } else {
          rows.push([element])
        }
      }
    })

    return rows
  }

  const pageTitle = category?.name || ""
  const data = React.useMemo(
    () => gridData(),
    [purchasableProducts, widgets, selectedFilters, variantsInventory]
  )
  const filterData = React.useMemo(
    () =>
      getFilterData(
        purchasableProducts,
        cart?.region?.name === "United States",
        Boolean(category.parent_category)
      ),
    [purchasableProducts, cart?.region, category]
  )

  const numOfProducts = data?.filter(
    (item) => item.variant === "product"
  )?.length
  const numOfWidgets = data?.filter((item) => item.variant === "widget")?.length

  const [openedDrawerId, setOpenedDrawerId] = React.useState<
    CategoryPageWidget["contentful_id"] | null
  >(null)
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = React.useState(false)
  const [locked, setLocked] = useLockedBody(false)
  const [lockedMobile, setLockedMobile] = useLockedBodyMobile(false)
  const [accordionValues, setAccordionValues] = React.useState<string[]>([])

  const getFirstPageLength = (data: Data[], defaultLength: number): number => {
    let visualLength = 0

    data.slice(0, defaultLength).forEach((item) => {
      if (item.variant === "product") {
        visualLength += 1
      } else if (item.variant === "widget") {
        if (item.size === "1x1") {
          visualLength += 1
        } else if (item.size === "2x2") {
          visualLength += 4
        }
      }
    })

    if (visualLength % 4 !== 0) {
      return defaultLength + (4 - (visualLength % 4))
    }

    return defaultLength
  }

  const getVisibleLength = (
    data: Data[],
    page: number,
    totalPages: number,
    contentPerPage: number
  ): number => {
    if (page === totalPages) {
      return data.length
    }

    return (
      getFirstPageLength(data, contentPerPage) + (page - 1) * contentPerPage
    )
  }

  const currentPage = searchParams.get("page")
    ? parseInt(searchParams.get("page"))
    : 1

  const CONTENT_PER_PAGE = 32
  const { nextPage, page, totalPages } = usePagination({
    contentPerPage: CONTENT_PER_PAGE,
    count: data.length,
    currentPage: currentPage,
  })
  const visibleLength = getVisibleLength(
    data,
    page,
    totalPages,
    CONTENT_PER_PAGE
  )

  React.useEffect(() => {
    const handleScroll = () => {
      if (
        document.documentElement.scrollTop + window.innerHeight >
          filterButtonBottomTriggerPointRef.current.offsetTop ||
        document.documentElement.scrollTop + window.innerHeight <
          filterButtonTopTriggerPointRef.current.offsetTop
      ) {
        filterFloatingButtonRef.current.style.opacity = 0
        filterFloatingButtonRef.current.style.visibility = "hidden"
      } else {
        filterFloatingButtonRef.current.style.opacity = 1
        filterFloatingButtonRef.current.style.visibility = "visible"
      }

      if (
        document.documentElement.scrollTop >
        filterNavigationAnchorRef.current.offsetTop
      ) {
        quickFiltersRef.current.style.display = "flex"
      } else {
        quickFiltersRef.current.style.display = "none"
      }
    }

    handleScroll()

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  React.useEffect(() => {
    if (cart?.region_id) {
      refetchPrices()
    }
  }, [cart?.region_id])

  React.useEffect(() => {
    if (!categoryGridRef.current) {
      return
    }

    // Get elements grouped by their bottom position
    const rows = getRows(Array.from(categoryGridRef.current.children))

    const handleResize = () => {
      // Set the height of each image element to the highest footer height in the row
      rows.forEach((row) => {
        const highestFooterHeight = Math.max(
          ...row.map((element: HTMLElement) => {
            const footerElement = element.querySelector(
              "[data-footer]"
            ) as HTMLElement
            return footerElement.offsetHeight
          })
        )

        row.forEach((item: HTMLElement) => {
          const imageElement = item.querySelector("[data-image]") as HTMLElement
          imageElement.style.height = `calc(100% - ${
            highestFooterHeight + "px"
          })`
        })
      })
    }

    handleResize()

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [data, page, prices, categoryGridRef.current])

  const canonicalUrl = searchParams.get("page")
    ? `${seo.canonicalUrl}?page=${searchParams.get("page")}`
    : seo.canonicalUrl

  return (
    <Layout almostWhite>
      <SEO
        title={pageTitle}
        metaImage={seo.metaImageUrl && seo.metaImageUrl}
        description={seo.description}
        canonicalUrl={canonicalUrl}
        breadcrumbsList={seo.breadcrumbsList}
      />
      <Box sx={{ paddingBlockStart: [3, 21], paddingBlockEnd: [8, 18] }}>
        <Flex
          as="ul"
          sx={{
            fontSize: "sm",
            listStyleType: "none",
            gap: 1,
            paddingInline: 4,
            marginBlockEnd: 6,
            li: {
              marginBlockEnd: 0,
            },
          }}
        >
          <li>
            <Link to="/all-products">All products</Link>
          </li>
          {Boolean(category.parent_category) && (
            <>
              <li>–</li>
              <li>
                <Link to={`/category/${category.parent_category.handle}`}>
                  {category.parent_category.name}
                </Link>
              </li>
            </>
          )}
        </Flex>
        {shopstoryContent ? (
          <ShopstoryModule shopstoryContent={shopstoryContent} />
        ) : (
          <Box sx={{ paddingInline: 4, marginBlockEnd: 8 }}>
            <Heading as="h1" sx={{ display: "inline", fontSize: "xl" }}>
              {pageTitle}
            </Heading>
            {category.description && (
              <Heading as="h2" sx={{ display: "inline", fontSize: "xl" }}>
                &nbsp;– {category.description}
              </Heading>
            )}
          </Box>
        )}
        {Boolean(category.parent_category) &&
        filterData?.product?.length > 1 ? (
          <Box
            sx={{
              overflowX: ["scroll", "unset"],
              borderBlock: ["1px solid", "none"],
              borderBlockColor: ["grayscale.300", "transparent"],
              scrollbarWidth: "none",
              paddingBlock: [5, 0],
              marginInline: [0, 4],
              marginBlockStart: 6,
              marginBlockEnd: 4,
              "::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            <Flex sx={{ fontSize: "sm", gap: 6 }}>
              <Box sx={{ flexShrink: 0, paddingInlineStart: [4, 0] }}>
                <Link
                  sx={{
                    borderBlockEnd:
                      selectedFilters.product?.length === 0 ? "1px solid" : 0,
                  }}
                  to={location.pathname}
                >
                  All
                </Link>
              </Box>
              {sortByRank(filterData.product, category.id)?.map((type) => (
                <Box key={type.value} sx={{ flexShrink: 0 }}>
                  <Link
                    sx={{
                      borderBlockEnd:
                        selectedFilters.product?.length === 1 &&
                        selectedFilters.product?.[0] === type.value
                          ? "1px solid"
                          : 0,
                    }}
                    to={`${location.pathname}?product=${type.value}`}
                  >
                    {type.label}
                  </Link>
                </Box>
              ))}
            </Flex>
          </Box>
        ) : null}
        <Box ref={filterNavigationAnchorRef} />
        <Flex
          ref={categoryStickyBarRef}
          sx={{
            height: 13,
            backgroundColor: "grayscale.100",
            position: ["initial", "sticky"],
            top: "-1px",
            zIndex: "categoryBar",
            fontSize: "sm",
            alignItems: "center",
            justifyContent: "space-between",
            transform:
              scrollDir === "down"
                ? "translateY(0)"
                : scrollDir === "up" &&
                  scrollPosition.top > categoryStickyBarRef.current.offsetTop
                ? ["translateY(0)", "translateY(100%)"]
                : null,
            transition: "transform .25s",
            paddingInline: 4,
            marginBlockEnd: 3,
          }}
        >
          <Flex sx={{ gap: 3 }}>
            {shopstoryContent && <Text>{pageTitle}</Text>}
            <Text sx={{ color: "grayscale.600" }}>
              {numOfProducts} products
            </Text>
          </Flex>
          <Box sx={{ display: ["none", "flex"], gap: 5 }}>
            <Button
              variant="link"
              iconLeft={
                <Icon name="sliders" size="4" sx={{ marginBlockEnd: 1 }} />
              }
              onClick={() => {
                setIsFilterDrawerOpen(true)
                setLocked(true)
              }}
            >
              Filter
              {getSelectedFiltersNumber(selectedFilters) > 0 &&
                " (" + getSelectedFiltersNumber(selectedFilters) + ")"}
            </Button>
            <Box
              ref={quickFiltersRef}
              sx={{ display: ["none", "flex"], gap: 5 }}
            >
              {Object.keys(filterData)
                .filter(
                  (value) =>
                    !(
                      Array.isArray(filterData[value]) &&
                      filterData[value].length < 2
                    )
                )
                .map((filterType) => (
                  <Button
                    key={filterType}
                    variant="link"
                    onClick={() => {
                      setAccordionValues([filterType])
                      setIsFilterDrawerOpen(true)
                    }}
                  >
                    {capitalize(filterType)}{" "}
                    {selectedFilters?.[filterType]?.length > 0
                      ? `(${selectedFilters?.[filterType]?.length})`
                      : filterType === "price" &&
                        selectedFilters["price_min"] !== null &&
                        selectedFilters["price_max"] !== null
                      ? "(1)"
                      : null}
                  </Button>
                ))}
            </Box>
          </Box>
        </Flex>
        <Box ref={filterButtonTopTriggerPointRef} />
        <UiCategoryGrid ref={categoryGridRef}>
          {data?.length > 0 &&
            data.slice(0, visibleLength).map((item) => {
              if (item.variant === "widget") {
                if (item.background?.file?.contentType?.startsWith("image")) {
                  const buttonPosition = item.buttonPosition ? "start" : "end"
                  const buttonColor = item.buttonColor ? "light" : "dark"
                  const buttonVariant = item.buttonVariant
                    ? "folded"
                    : "unfolded"

                  const alignmentInGrid = item.alignmentInGrid ? "start" : "end"

                  return (
                    <FeaturedProductsWidget
                      title={item.title}
                      hideButton={true}
                      handle={item.url}
                      size={item.size === "2x2" ? "large" : "default"}
                      image={item.background.gatsbyImageData}
                      align={alignmentInGrid}
                      isFullWidthOnMobile={Boolean(item.fullWidthOnMobile)}
                      buttonPosition={buttonPosition}
                      buttonFoldedProps={
                        buttonVariant === "folded" && {
                          children: item.buttonLabel,
                          variant:
                            buttonColor === "light" ? "secondary" : "primary",
                        }
                      }
                      buttonUnfoldedProps={
                        buttonVariant === "unfolded" && {
                          children: item.buttonLabel,
                          variant:
                            buttonColor === "light" ? "secondary" : "primary",
                        }
                      }
                    />
                  )
                }

                if (item.background?.file?.contentType?.startsWith("video")) {
                  return (
                    <VisualItem
                      key={item.contentful_id}
                      title={item.title}
                      handle={item.url}
                      video={item.background.file.url}
                      size={
                        item.size === "1x1"
                          ? "default"
                          : item.size === "2x2"
                          ? "large"
                          : null
                      }
                    />
                  )
                }

                return (
                  <TextualItem
                    key={item.contentful_id}
                    title={item.title}
                    titleFontSize={item.titleFontSize}
                    description={item.description}
                    drawerContent={item.drawerContent}
                    buttonLabel={item.buttonLabel}
                    contentful_id={item.contentful_id}
                    size={
                      item.size === "1x1"
                        ? "default"
                        : item.size === "2x2"
                        ? "large"
                        : null
                    }
                    handle={item.url}
                    setOpenedDrawerId={setOpenedDrawerId}
                    setLocked={setLocked}
                  />
                )
              }
              return (
                <VisualItem
                  key={item.id}
                  title={item.title}
                  handle={item.handle}
                  image={item.image}
                  imageOnHover={item.imageOnHover}
                  priceMin={item.priceMin}
                  priceMax={item.priceMax}
                  selectedSize={selectedFilters.size?.[0]}
                />
              )
            })}
        </UiCategoryGrid>
        <Box
          ref={filterButtonBottomTriggerPointRef}
          sx={{
            width: 53,
            maxWidth: "100%",
            marginInline: "auto",
            textAlign: "center",
            marginBlockStart: [13, 18],
          }}
        >
          <Paragraph
            sx={{ fontSize: "sm", marginBlockEnd: data.length > 0 && 6 }}
          >
            {data.length > 0 ? (
              <>
                Showing{" "}
                {visibleLength -
                  data.filter((item) => item.variant === "widget").length}{" "}
                of {numOfProducts} products
              </>
            ) : (
              <>No results</>
            )}
          </Paragraph>
          {data.length > 0 && (
            <UiProgress value={(visibleLength / data.length) * 100} />
          )}
          {page !== totalPages && data.length > 0 && (
            <Button
              variant="ghost"
              href={`${location.pathname}?page=${page + 1}`}
              sx={{ marginBlockStart: 6 }}
              onClick={(e) => {
                e.preventDefault()
                nextPage()
                handlePageChange(page + 1)
              }}
              disabled={page === totalPages}
              isVisuallyDisabled={page === totalPages}
            >
              Show more
            </Button>
          )}
          {data.length < 1 && (
            <Button
              variant="ghost"
              sx={{ marginBlockStart: 6 }}
              onClick={() => {
                navigate(location.pathname)
              }}
            >
              Clear all filters
            </Button>
          )}
        </Box>
      </Box>
      {numOfWidgets > 0 &&
        widgets.map((widget) => {
          if (Boolean(widget.drawerContent)) {
            return (
              <Drawer
                key={widget.contentful_id}
                isOpened={openedDrawerId === widget.contentful_id}
                size="xl"
                onCloseClick={() => {
                  setOpenedDrawerId(null)
                  setLocked(false)
                }}
                onBackdropClick={() => {
                  setOpenedDrawerId(null)
                  setLocked(false)
                }}
                onSwipeRight={() => {
                  setOpenedDrawerId(null)
                  setLocked(false)
                }}
              >
                <ShopstoryModule
                  shopstoryContent={widget.compiledDrawerContent}
                />
              </Drawer>
            )
          }
        })}
      <Box sx={{ display: ["none", "block"] }}>
        <Drawer
          isOpened={isFilterDrawerOpen}
          size="xl"
          onCloseClick={() => {
            setIsFilterDrawerOpen(false)
            setLocked(false)
          }}
          onBackdropClick={() => {
            setIsFilterDrawerOpen(false)
            setLocked(false)
          }}
        >
          <FilterDrawerContent
            filterData={filterData}
            numOfProducts={numOfProducts}
            accordionValues={accordionValues}
            setAccordionValues={setAccordionValues}
            selectedSort={selectedSort}
            setSelectedSort={setSelectedSort}
            selectedFilters={selectedFilters}
            setIsFilterDrawerOpen={setIsFilterDrawerOpen}
            setLocked={setLocked}
            products={purchasableProducts}
            isUSA={cart?.region?.name === "United States"}
            filterNavigationAnchorRef={filterNavigationAnchorRef}
            isMobile={false}
          />
        </Drawer>
      </Box>
      <Box sx={{ display: ["flex", "none"] }}>
        <Drawer
          isOpened={isFilterDrawerOpen}
          position="bottom"
          size="xl"
          onCloseClick={() => {
            setIsFilterDrawerOpen(false)
            setLockedMobile(false)
          }}
          onBackdropClick={() => {
            setIsFilterDrawerOpen(false)
            setLockedMobile(false)
          }}
          onSwipeRight={() => {
            setIsFilterDrawerOpen(false)
            setLockedMobile(false)
          }}
        >
          <FilterDrawerContent
            filterData={filterData}
            numOfProducts={numOfProducts}
            accordionValues={accordionValues}
            setAccordionValues={setAccordionValues}
            selectedSort={selectedSort}
            setSelectedSort={setSelectedSort}
            selectedFilters={selectedFilters}
            setIsFilterDrawerOpen={setIsFilterDrawerOpen}
            setLockedMobile={setLockedMobile}
            products={purchasableProducts}
            isUSA={cart?.region?.name === "United States"}
            filterNavigationAnchorRef={filterNavigationAnchorRef}
            isMobile={true}
          />
        </Drawer>
      </Box>
      <Box
        ref={filterFloatingButtonRef}
        sx={{
          transition: "opacity 0.2s, visibility 0.2s",
          display: ["block", "none"],
        }}
      >
        <Button
          variant="secondary"
          iconLeft={<Icon name="sliders" size="4" sx={{ marginBlockEnd: 1 }} />}
          onClick={() => {
            setIsFilterDrawerOpen(true)
            setLockedMobile(true)
          }}
          sx={{
            position: "fixed",
            bottom: 10,
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          Filter
          {getSelectedFiltersNumber(selectedFilters) > 0 &&
            " (" + getSelectedFiltersNumber(selectedFilters) + ")"}
        </Button>
      </Box>
    </Layout>
  )
}

export const UiCategoryGrid: React.FC<GridProps> = React.forwardRef(
  ({ sx, ...rest }, ref) => (
    <Grid
      {...rest}
      ref={ref}
      sx={{
        gridAutoFlow: "dense",
        gridTemplateColumns: ["repeat(2, 1fr)", "repeat(4, 1fr)"],
        gridTemplateRows: "1fr",
        columnGap: 4,
        rowGap: 8,
        paddingInline: 4,
        ...sx,
      }}
    />
  )
)

type VisualItemProps = LinkProps & {
  handle: string
  image?: IGatsbyImageData
  imageOnHover?: IGatsbyImageData
  video?: string
  title: string
  size?: "default" | "large"
  priceMin?: number
  priceMax?: number
  selectedSize?: string
  wrapperClassName?: string
  titleClassName?: string
  priceClassName?: string
}

export const VisualItem: React.FC<VisualItemProps> = ({
  handle,
  image,
  imageOnHover,
  video,
  title,
  size,
  priceMin,
  priceMax,
  selectedSize,
  wrapperClassName,
  titleClassName,
  priceClassName,
}) => {
  const { cart } = useStore()
  return (
    <Box
      className={wrapperClassName}
      sx={{
        gridColumn: size === "large" && "1 / span 2",
        gridRow: size === "large" && "span 2",
        "> a": {
          color: "primary",
          textDecoration: "none",
          display: "flex",
          flexDirection: "column",
          position: "relative",
          height: "100%",
          "&:hover .hover-image": {
            opacity: [0, 1],
            visibility: ["hidden", "visible"],
          },
          picture: {
            width: "100%",
            aspectRatio: "4 / 5",
          },
        },
      }}
    >
      <GatsbyLink
        to={handle}
        state={{ size: selectedSize, scrollTo: { x: 0, y: 0 } }}
      >
        <Box
          data-image
          sx={{
            position: "relative",
            width: "100%",
            aspectRatio: "4 / 5",
          }}
        >
          <Box sx={{ height: "100%" }}>
            {image ? (
              <picture>
                {image?.images.sources.map((source, index) => (
                  <source
                    key={index}
                    type={source.type}
                    srcSet={source.srcSet}
                    sizes={source.sizes}
                  />
                ))}
                <Image
                  width={image?.width}
                  height={image?.height}
                  sizes={image?.images?.fallback?.sizes}
                  decoding="async"
                  loading="lazy"
                  src={image?.images?.fallback?.src}
                  srcSet={image?.images?.fallback?.srcSet}
                  alt={title}
                  sx={{
                    display: "block",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    transform: "translateZ(0)",
                  }}
                />
              </picture>
            ) : null}
            {video ? (
              <Box
                sx={{
                  height: "100%",
                  video: {
                    display: "block",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  },
                }}
              >
                <video src={video} autoPlay muted={true} loop playsInline>
                  <p>
                    Your browser doesn't support HTML video. Here is a
                    <a href={video}>link to the video</a> instead.
                  </p>
                </video>
              </Box>
            ) : null}
          </Box>
          {Boolean(imageOnHover) && (
            <Box
              className="hover-image"
              sx={{
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0,
                opacity: 0,
                visibility: "hidden",
              }}
            >
              <picture>
                {imageOnHover.images.sources.map((source, index) => (
                  <source
                    key={index}
                    type={source.type}
                    srcSet={source.srcSet}
                    sizes={source.sizes}
                  />
                ))}
                <Image
                  width={imageOnHover.width}
                  height={imageOnHover.height}
                  sizes={imageOnHover.images?.fallback?.sizes}
                  decoding="async"
                  loading="lazy"
                  src={imageOnHover.images?.fallback?.src}
                  srcSet={imageOnHover.images?.fallback?.srcSet}
                  alt={title}
                  sx={{
                    display: "block",
                    height: "100%",
                    objectFit: "cover",
                    transform: "translateZ(0)",
                  }}
                />
              </picture>
            </Box>
          )}
        </Box>
        <Box data-footer>
          {Boolean(title) && (
            <Paragraph
              sx={{
                fontSize: "sm",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                paddingBlockStart: 2,
              }}
              className={titleClassName}
            >
              {title}
            </Paragraph>
          )}
          {Boolean(priceMin) && Boolean(priceMax) && (
            <Paragraph
              sx={{ fontSize: "sm", marginBlockStart: 1 }}
              className={priceClassName}
            >
              {priceMin === priceMax ? (
                formatMoneyAmount({
                  amount: priceMin,
                  currencyCode: cart?.region?.currency_code,
                })
              ) : (
                <>
                  {formatMoneyAmount({
                    amount: priceMin,
                    currencyCode: cart?.region?.currency_code,
                  })}{" "}
                  -{" "}
                  {formatMoneyAmount({
                    amount: priceMax,
                    currencyCode: cart?.region?.currency_code,
                  })}
                </>
              )}
            </Paragraph>
          )}
        </Box>
      </GatsbyLink>
    </Box>
  )
}

type TextualItemProps = BoxProps & {
  title: CategoryPageWidget["title"]
  titleFontSize: CategoryPageWidget["titleFontSize"]
  description: CategoryPageWidget["description"]
  drawerContent: CategoryPageWidget["drawerContent"]
  buttonLabel: CategoryPageWidget["buttonLabel"]
  handle: CategoryPageWidget["url"]
  contentful_id: CategoryPageWidget["contentful_id"]
  size?: "default" | "large"
  setOpenedDrawerId: (value: CategoryPageWidget["contentful_id"] | null) => void
  setLocked: (value: boolean) => void
}

export const TextualItem: React.FC<TextualItemProps> = React.forwardRef(
  (
    {
      title,
      titleFontSize,
      description,
      buttonLabel,
      drawerContent,
      handle,
      contentful_id,
      size,
      setOpenedDrawerId,
      setLocked,
      ...rest
    },
    ref
  ) => (
    <Flex
      {...rest}
      ref={ref}
      sx={{
        height: "100%",
        gap: [4, 5],
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        // gridColumn:
        //   size === "large" && align === "start"
        //     ? "1 / span 2"
        //     : size === "large" && align === "end"
        //     ? "3 / span 2"
        //     : null,
        gridColumn: size === "large" && "1 / span 2",
        gridRow: size === "large" && "span 2",
      }}
      data-textual
    >
      <Heading
        sx={{
          fontSize:
            titleFontSize === null
              ? "xl"
              : [`calc(${titleFontSize} - 4px)`, titleFontSize],
        }}
      >
        {title}
      </Heading>
      <Flex sx={{ flexDirection: "column", gap: 3, fontSize: "sm" }}>
        <Paragraph>{description}</Paragraph>
      </Flex>
      {Boolean(drawerContent) ? (
        <Button
          onClick={() => {
            setOpenedDrawerId(contentful_id)
            setLocked(true)
          }}
          variant="ghost"
        >
          {buttonLabel}
        </Button>
      ) : (
        <Button variant="ghost" to={handle}>
          {buttonLabel}
        </Button>
      )}
    </Flex>
  )
)

export const ShopstoryModule = ({ shopstoryContent }) => {
  return (
    <TeklaShopstoryProvider>
      <Shopstory {...shopstoryContent} />
    </TeklaShopstoryProvider>
  )
}

export const FilterDrawerContent = ({
  filterData,
  numOfProducts,
  accordionValues,
  setAccordionValues,
  selectedSort,
  setSelectedSort,
  selectedFilters,
  setIsFilterDrawerOpen,
  setLocked,
  setLockedMobile,
  products,
  isUSA,
  filterNavigationAnchorRef,
  isMobile,
}) => {
  const [selected, setSelected] =
    React.useState<SelectedFilters>(selectedFilters)
  const { cart } = useStore()

  const getFilterValueLabel = (filterType: string, value: string) => {
    const filter = filterData[filterType].find((item) => item.value === value)
    return filter?.label
  }

  // Handle filter changes
  const handleFilterChange = (filterType: string, value: string | []) => {
    if (filterType === "price") {
      setSelected((prevFilters) => {
        return {
          ...prevFilters,
          price_min: value[0],
          price_max: value[1],
        }
      })
    } else {
      setSelected((prevFilters) => {
        // If value is already in the array, remove it, otherwise add it
        const values = prevFilters[filterType]
        if (values.includes(value)) {
          values.splice(values.indexOf(value), 1)
        } else {
          values.push(value)
        }

        return {
          ...prevFilters,
          [filterType]: values,
        }
      })
    }
  }

  // Handle view products
  const handleViewProducts = () => {
    // Ignore empty values
    const newFiltersEntries = Object.entries(selected).filter(
      ([key, value]) =>
        (key === "price_min" && value && value >= 0) ||
        (key === "price_max" && value && value >= 0) ||
        value?.length > 0
    )

    // Add sort parameter
    if (selectedSort) {
      newFiltersEntries.push(["sort", [selectedSort]])
    }

    // Update URL with new query parameters
    const queryParams = new URLSearchParams(newFiltersEntries)

    navigate(`${location.pathname}?${queryParams.toString()}`, {
      state: {
        scrollTo: {
          x: 0,
          y: filterNavigationAnchorRef.current.offsetTop - 52,
        },
      },
    })
  }

  React.useEffect(() => {
    setSelected(selectedFilters)
  }, [selectedFilters])

  const filter = React.useMemo(() => {
    const filteredProducts = filterProducts(products, selected)
    const filterData = getFilterData(filteredProducts, isUSA)
    return {
      ...filterData,
      numOfFilteredProducts: filteredProducts?.length,
    }
  }, [products, selected])

  return (
    <>
      <Box sx={{ marginBlockEnd: 10 }}>
        <Paragraph sx={{ fontSize: "xl" }}>Filter & sort</Paragraph>
        <Paragraph sx={{ color: "grayscale.600", fontSize: "xs" }}>
          {numOfProducts} products
        </Paragraph>
      </Box>
      <Accordion.Root
        type="multiple"
        value={accordionValues}
        onValueChange={setAccordionValues}
      >
        <Accordion.Item asChild value="sort">
          <UiAccordionItem>
            <Accordion.Header asChild>
              <UiAccordionHeader>
                <Accordion.Trigger asChild>
                  <UiAccordionTrigger>Sort</UiAccordionTrigger>
                </Accordion.Trigger>
              </UiAccordionHeader>
            </Accordion.Header>
            <Accordion.Content asChild>
              <UiAccordionContent>
                <RadioGroup.Root>
                  <Grid gap={5} columns={["repeat(2, 1fr)"]}>
                    {SORT_OPTIONS?.map((option) => (
                      <UiRadioGroupField key={option.value}>
                        <RadioGroup.Item
                          value={option.value}
                          id={option.value}
                          asChild
                          checked={selectedSort === option.value}
                          onClick={(e) => {
                            setSelectedSort((prevState) =>
                              prevState === e.target.value ? "" : e.target.value
                            )
                          }}
                        >
                          <UiRadioGroupItem />
                        </RadioGroup.Item>
                        <UiRadioGroupLabel htmlFor={option.value}>
                          {option.label}
                        </UiRadioGroupLabel>
                      </UiRadioGroupField>
                    ))}
                  </Grid>
                </RadioGroup.Root>
              </UiAccordionContent>
            </Accordion.Content>
          </UiAccordionItem>
        </Accordion.Item>
        {Object.keys(filterData)
          .filter(
            (value) =>
              !(
                Array.isArray(filterData[value]) && filterData[value].length < 2
              )
          )
          .map((value) => (
            <React.Fragment key={value}>
              <Accordion.Item asChild value={value}>
                <UiAccordionItem>
                  <Accordion.Header asChild>
                    <UiAccordionHeader>
                      <Accordion.Trigger asChild>
                        <UiAccordionTrigger
                          sideContent={selected?.[value]?.length}
                        >
                          {capitalize(value)}
                        </UiAccordionTrigger>
                      </Accordion.Trigger>
                    </UiAccordionHeader>
                  </Accordion.Header>
                  <Accordion.Content asChild>
                    <UiAccordionContent>
                      {Array.isArray(filterData[value]) ? (
                        <Grid gap={5} columns={["repeat(2, 1fr)"]}>
                          {filterData[value].map((item) => {
                            // Filter value is disabled if filter type (e.g. color, pattern) is not already selected and if its value is not present in new filter inofrmation
                            const isDisabled =
                              selected[value]?.length < 1 &&
                              !filter?.[value]?.some(
                                (i) => i.value === item.value
                              )
                            return (
                              <Flex key={item.value}>
                                <Checkbox.Root
                                  id={
                                    isMobile
                                      ? item.value + "-mobile"
                                      : item.value + "-desktop"
                                  }
                                  asChild
                                  checked={
                                    selected[value]?.includes(item.value) ||
                                    false
                                  }
                                  onClick={() => {
                                    if (!isDisabled) {
                                      handleFilterChange(value, item.value)
                                    }
                                  }}
                                  disabled={isDisabled}
                                >
                                  <UiRadixCheckbox>
                                    <Checkbox.Indicator forceMount asChild>
                                      <UiRadixCheckboxIcon name="checkmark" />
                                    </Checkbox.Indicator>
                                  </UiRadixCheckbox>
                                </Checkbox.Root>
                                <UiRadixCheckboxLabel
                                  htmlFor={
                                    isMobile
                                      ? item.value + "-mobile"
                                      : item.value + "-desktop"
                                  }
                                >
                                  {isDisabled ? (
                                    <Text
                                      sx={{
                                        color: "grayscale.400",
                                        textDecoration: "line-through",
                                      }}
                                    >
                                      {item.label}
                                    </Text>
                                  ) : (
                                    item.label
                                  )}
                                </UiRadixCheckboxLabel>
                              </Flex>
                            )
                          })}
                        </Grid>
                      ) : value === "price" ? (
                        <PriceRangeSlider
                          min={filterData?.price?.min}
                          max={filterData?.price?.max}
                          currencyCode={cart?.region?.currency_code}
                          handleValueChange={(value) =>
                            handleFilterChange("price", value)
                          }
                          defaultValues={[
                            selected.price_min || filterData?.price?.min,
                            selected.price_max || filterData?.price?.max,
                          ]}
                        />
                      ) : null}
                    </UiAccordionContent>
                  </Accordion.Content>
                </UiAccordionItem>
              </Accordion.Item>
            </React.Fragment>
          ))}
      </Accordion.Root>
      <Flex
        sx={{
          flexDirection: "column",
          gap: 4,
          width: "100%",
          maxWidth: 126,
          backgroundColor: "grayscale.white",
          position: "sticky",
          bottom: 0,
          paddingBlock: 7,
          marginBlockEnd: -10,
        }}
      >
        <Flex
          sx={{
            flexWrap: "wrap",
            alignItems: "center",
            gap: 3,
          }}
        >
          {Object.entries(selected)
            .filter(([filterType, values]) => Array.isArray(values))
            .map(([filterType, values]) => {
              return values?.map((value) => (
                <Flex
                  key={value}
                  sx={{
                    height: 8,
                    alignItems: "center",
                    gap: 2,
                    border: "1px solid",
                    borderColor: "grayscale.600",
                    paddingInline: 2,
                  }}
                >
                  <Text>{getFilterValueLabel(filterType, value)}</Text>
                  <Button
                    variant="link"
                    onClick={() => handleFilterChange(filterType, value)}
                  >
                    <Icon name="x" size={3} />
                  </Button>
                </Flex>
              ))
            })}
          {Object.values(selected).some((value) => Boolean(value?.length)) && (
            <Box>
              <Button
                variant="link"
                onClick={() =>
                  setSelected({
                    color: [],
                    fabric: [],
                    pattern: [],
                    product: [],
                    size: [],
                    price_min: undefined,
                    price_max: undefined,
                  })
                }
              >
                Clear all
              </Button>
            </Box>
          )}
        </Flex>
        <Button
          variant="primary"
          onClick={() => {
            trackFilterProducts({
              filter_sort: selectedSort || "",
              filter_color: selected.color?.join(",") || "",
              filter_product: selected.product?.join(",") || "",
              filter_size: selected.size?.join(",") || "",
              filter_pattern: selected.pattern?.join(",") || "",
              filter_fabric: selected.fabric?.join(",") || "",
              filter_price_min: selected.price_min || "",
              filter_price_max: selected.price_max || "",
            })
            handleViewProducts()
            setIsFilterDrawerOpen(false)

            if (setLockedMobile !== undefined) {
              setLockedMobile(false)
            } else {
              setLocked(false)
            }
          }}
          sx={{ width: "100%" }}
          disabled={filter.numOfFilteredProducts === 0}
          isVisuallyDisabled={filter.numOfFilteredProducts === 0}
        >
          View {filter.numOfFilteredProducts} products
        </Button>
      </Flex>
    </>
  )
}

type PriceRangeSliderProps = BoxProps & {
  min: number
  max: number
  defaultValues: number[]
  currencyCode: string
  handleValueChange: (value: number[]) => void
}

const PriceRangeSlider: React.FC<PriceRangeSliderProps> = React.forwardRef(
  (
    { min, max, defaultValues, currencyCode, handleValueChange, ...rest },
    ref
  ) => {
    const [priceRangeValues, setPriceRangeValues] =
      React.useState<number[]>(defaultValues)

    const handleInputOnChange = (event, valueIndex) => {
      const value = Number(event.target.value)

      if (value < event.target.min || value > event.target.max) {
        //return
      }

      if (valueIndex === 0) {
        setPriceRangeValues((prevValue) => [value, prevValue[1]])
        handleValueChange([value, priceRangeValues[1]])
        return
      }

      if (valueIndex === 1) {
        setPriceRangeValues((prevValue) => [prevValue[0], value])
        handleValueChange([priceRangeValues[0], value])
        return
      }
    }

    React.useEffect(() => {
      setPriceRangeValues(defaultValues)
    }, [defaultValues])

    return (
      <Box {...rest} ref={ref}>
        <Slider.Root
          value={priceRangeValues}
          defaultValue={priceRangeValues}
          minStepsBetweenThumbs={1}
          min={min}
          max={max}
          step={1}
          onValueChange={(value) => {
            setPriceRangeValues(value)
          }}
          onValueCommit={(value) => handleValueChange(value)}
          asChild
        >
          <UiSlider sx={{ marginBlockEnd: 6 }}>
            <Slider.Track asChild>
              <UiSliderTrack>
                <Slider.Range asChild>
                  <UiSliderRange />
                </Slider.Range>
              </UiSliderTrack>
            </Slider.Track>
            <Slider.Thumb asChild>
              <UiSliderThumb />
            </Slider.Thumb>
            <Slider.Thumb asChild>
              <UiSliderThumb />
            </Slider.Thumb>
          </UiSlider>
        </Slider.Root>
        <Flex sx={{ alignItems: "center" }}>
          <Box sx={{ position: "relative", flex: 1 }}>
            <Input
              type="number"
              value={priceRangeValues?.[0]}
              min={min}
              max={max}
              onChange={(event) => handleInputOnChange(event, 0)}
              sx={{
                textAlign: "center",
                borderRadius: 0,
                borderColor: "grayscale.200",
                "&:focus": {
                  outline: "none",
                },
                "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
                  appearance: "none",
                },
              }}
            />
            <Text
              sx={{
                position: "absolute",
                top: "50%",
                right: 3,
                transform: "translateY(-50%)",
              }}
            >
              {currencyCode}
            </Text>
          </Box>
          <Box sx={{ width: 16, textAlign: "center", flexShrink: 0 }} />
          <Box sx={{ position: "relative", flex: 1 }}>
            <Input
              type="number"
              value={priceRangeValues?.[1]}
              min={min}
              max={max}
              onChange={(event) => handleInputOnChange(event, 1)}
              sx={{
                textAlign: "center",
                borderRadius: 0,
                borderColor: "grayscale.200",
                "&:focus": {
                  outline: "none",
                },
                "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
                  appearance: "none",
                },
              }}
            />
            <Text
              sx={{
                position: "absolute",
                top: "50%",
                right: 3,
                transform: "translateY(-50%)",
              }}
            >
              {currencyCode}
            </Text>
          </Box>
        </Flex>
      </Box>
    )
  }
)

PriceRangeSlider.displayName = "PriceRangeSlider"

export default CategoryTemplate
