// External packages
import * as React from "react"
import * as Dialog from "@radix-ui/react-dialog"
import { Box, Flex, Paragraph } from "theme-ui"
import { keyframes } from "@emotion/react"
import { navigate } from "gatsby-link"
import { PricedProduct } from "@medusajs/medusa/dist/types/pricing"

// Components
import { Input } from "../v2/ui/Input"
import { Link } from "../v2/Link"
import { Icon } from "../v2/ui/Icon"
import {
  VisualItem,
  UiCategoryGrid,
} from "../../templates/medusa/CategoryTemplate"
import { Button } from "../v2/Button"

// Utilities
import { getMinimumPrice } from "./utils/product/getMinimumPrice"
import { getMaximumPrice } from "./utils/product/getMaximumPrice"
import { getSearchResultTitle } from "../../templates/SearchTemplate"

// Hooks
import { useDebounce } from "use-debounce"
import useLockedBody from "../../hooks/v2/useLockedBody"
import { useAutocomplete } from "../../hooks/luigis-box/queries"
import { generateGatsbyImageData } from "../../shopstory/gatsby/imageUtils"
import { trackSearchProductClicked } from "../../services/analytics"
import { useStore } from "../../context/NewStoreContext"

export const SearchDrawer: React.FC<
  Dialog.DialogProps & {
    triggerChildren: React.ReactNode
  }
> = ({ children, open, ...rest }) => {
  const [locked, setLocked] = useLockedBody(false)
  const [dialogOpen, setDialogOpen] = React.useState(open)
  const [searchTerm, setSearchTerm] = React.useState("")
  const [debouncedSearchTerm] = useDebounce(searchTerm, 100)
  const { cart } = useStore()
  const { data, isFetching } = useAutocomplete(debouncedSearchTerm, cart, 12)

  const suggestions = data?.slice(0, 3)

  const isLoading = isFetching && Boolean(debouncedSearchTerm)

  const fadeIn = keyframes({
    from: { opacity: 0.2 },
    to: { opacity: 1 },
  })

  return (
    <Dialog.Root open={dialogOpen} onOpenChange={setDialogOpen} {...rest}>
      <Dialog.Trigger asChild onClick={() => setLocked(true)}>
        {children}
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="drawer-overlay" />
        <Dialog.Content asChild className="drawer-content-slide">
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              position: "fixed",
              top: 0,
              right: 0,
              zIndex: 9999,
              width: "100%",
              height: "100vh",
            }}
          >
            {data?.length ? (
              <Box sx={{ flexGrow: 1, backgroundColor: "grayscale.white" }}>
                <Box sx={{ overflowY: "scroll", height: "100%" }}>
                  <UiCategoryGrid
                    sx={{
                      gridTemplateColumns: [
                        "1fr",
                        "repeat(2, 1fr)",
                        "repeat(2, 1fr)",
                        "repeat(4, 1fr)",
                      ],
                      paddingBlock: 14,
                    }}
                  >
                    {data.map((item: PricedProduct, index: number) => (
                      <VisualItem
                        key={index}
                        title={getSearchResultTitle(item)}
                        handle={`/product/${item.handle}`}
                        image={generateGatsbyImageData({
                          imageUrl: item?.thumbnail?.startsWith("https:")
                            ? item.thumbnail
                            : `https:${item.thumbnail}`,
                        })}
                        priceMin={getMinimumPrice(item.variants)}
                        priceMax={getMaximumPrice(item.variants)}
                        onClick={() =>
                          trackSearchProductClicked({
                            query: debouncedSearchTerm,
                            product: item,
                          })
                        }
                        size="default"
                        wrapperClassName="luigisbox-search-result-item"
                        titleClassName="luigisbox-search-result-item-title"
                        priceClassName="luigisbox-search-result-item-price"
                      />
                    ))}
                  </UiCategoryGrid>
                  <Box sx={{ textAlign: "center" }}>
                    <Button href={`/search?q=${searchTerm}`} variant="ghost">
                      View all results
                    </Button>
                  </Box>
                </Box>
              </Box>
            ) : null}
            <Box
              sx={{
                backgroundColor: "grayscale.white",
                width: 157,
                flexBasis: 157,
                flexShrink: 0,
                maxWidth: "50%",
              }}
            >
              <Box
                sx={{
                  height: "100%",
                  overflowY: "scroll",
                  paddingBlockEnd: 14,
                  paddingInline: [8, 8, 15],
                }}
              >
                <Box
                  sx={{
                    position: "sticky",
                    top: 0,
                    backgroundColor: "grayscale.white",
                    paddingBlockStart: 14,
                    paddingBlockEnd: 4,
                  }}
                >
                  <form
                    onSubmit={(e) => {
                      e.preventDefault()
                      setLocked(false)
                      navigate(`/search?q=${searchTerm}`, {
                        replace: true,
                      })
                    }}
                  >
                    <Input
                      placeholder="Search"
                      iconName="search"
                      hasReset
                      value={searchTerm}
                      className="luigisbox-search-input"
                      onChange={(e) => setSearchTerm(e.target.value)}
                      sx={{ marginBlockEnd: 10 }}
                    />
                  </form>
                  {data?.length ? (
                    <Paragraph sx={{ color: "grayscale.600" }}>
                      Suggestions:
                    </Paragraph>
                  ) : (
                    <Paragraph sx={{ color: "grayscale.600" }}>
                      {debouncedSearchTerm && !isLoading ? (
                        <p className="luigisbox-no-results-autocomplete">
                          No results found
                        </p>
                      ) : null}
                      {isLoading ? (
                        <Flex
                          className="luigisbox-autocomplete-loading-spinner"
                          sx={{
                            justifyContent: "center",
                            div: {
                              width: 1,
                              height: 1,
                              borderRadius: "100%",
                              backgroundColor: "currentColor",
                              marginInline: 1,
                              opacity: 0.2,
                            },
                          }}
                        >
                          <Box
                            sx={{
                              animation: `${fadeIn} .6s linear infinite alternate`,
                            }}
                          />
                          <Box
                            sx={{
                              animation: `${fadeIn} .6s linear .15s infinite alternate`,
                            }}
                          />
                          <Box
                            sx={{
                              animation: `${fadeIn} .6s linear .30s infinite alternate`,
                            }}
                          />
                        </Flex>
                      ) : null}
                    </Paragraph>
                  )}
                </Box>
                <Box
                  as="ul"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 4,
                    listStyleType: "none",
                    margin: 0,
                  }}
                  className="luigisbox-search-suggestions"
                >
                  {suggestions?.length
                    ? suggestions?.map((item, index) => (
                        <Box
                          as="li"
                          key={index}
                          className="luigisbox-search-suggestion"
                        >
                          <Link
                            onClick={() =>
                              trackSearchProductClicked({
                                query: debouncedSearchTerm,
                                product: item,
                              })
                            }
                            to={`/product/${item.handle}`}
                          >
                            {item.title}
                          </Link>
                        </Box>
                      ))
                    : null}
                </Box>
                <Dialog.Close asChild onClick={() => setLocked(false)}>
                  <Box
                    as="button"
                    sx={{
                      position: "absolute",
                      top: 4,
                      right: 4,
                      backgroundColor: "transparent",
                      padding: 0,
                      border: 0,
                      cursor: "pointer",
                    }}
                  >
                    <Icon name="x" size={5} />
                  </Box>
                </Dialog.Close>
              </Box>
            </Box>
          </Box>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
